import React from 'react';
import styled from 'styled-components';
import { FiHome, FiCheckSquare, FiClock } from 'react-icons/fi';
import { breakpoints } from '../../utils/constants';

import FeatureTile from './FeatureTile';

const FeaturesContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const Features = () => (
  <FeaturesContainer>
    <FeatureTile
      icon={<FiHome />}
      title="Local Development"
      text="Your development team is in your time zone and able to meet in person to better understand your needs."
    />
    <FeatureTile
      icon={<FiCheckSquare />}
      title="Superior Quality"
      text="Your web presence is a reflection of your business. Make a stunning first impression with a professional site."
    />
    <FeatureTile
      icon={<FiClock />}
      title="Increase Efficiency"
      text="Build a software solution to automate boring, repetitive tasks. Save your limited time for other responsibilities."
    />
  </FeaturesContainer>
);

export default Features;
