import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints, colors } from '../../utils/constants';

const StyledIcon = styled.div`
  color: ${colors.cpOrange};
  margin-bottom: 1rem;
  font-size: 3rem;
  transition: 500ms;
`;

const Underline = styled.div`
  position: relative;
  z-index: 1;
  background: #ff4800;
  width: 55px;
  height: 3px;
  margin: 0.5rem 0 2rem;
  transition: 500ms;

  &::before {
    right: 20px;
  }

  ::after {
    right: 10px;
  }

  &::before,
  ::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    top: 0;
    background: #ffffff;
    transition: 0.5s;
  }
`;

const FeatureTileContainer = styled.div`
  padding: 3rem 2rem;
  width: 30%;
  background: #f7f7ff;
  transition: 500ms;

  p,
  h3 {
    margin: 0;
    padding: 0;
  }

  &:hover {
    color: #fff;
    background: ${colors.cpOrange};
  }

  &:hover ${StyledIcon} {
    color: #fff;
  }

  &:hover h3 {
    color: #fff;
    transition: 500ms;
  }

  &:hover ${Underline} {
    background: #fff;
    transition: 500ms;

    &::before,
    ::after {
      background: ${colors.cpOrange};
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const FeatureTile = ({ icon, text, title }) => (
  <FeatureTileContainer>
    <StyledIcon>{icon}</StyledIcon>
    <h3>{title}</h3>
    <Underline />
    <p>{text}</p>
  </FeatureTileContainer>
);

FeatureTile.propTypes = {
  icon: PropTypes.shape().isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FeatureTile;
