import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../utils/constants';

const Content = styled.div`
  background: #f9f9f9;
  padding: 35px 15px;
  transition: 0.5s;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: 500ms;
    background: ${colors.cpOrange};
    z-index: -1;
  }
`;

const ContentTitle = styled.h3`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  transition: 500ms;
`;

const ContentSubtitle = styled.h4`
  color: rgb(132, 132, 132);
  font-size: 20px;
  font-weight: 400;
  margin: 8px 0 0 0;
  transition: 500ms;
`;

const ProjectTileContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 2rem;
  width: 290px;

  &:hover {
    ${Content}::before {
      height: 100%;
    }

    h3,
    h4 {
      color: #fff;
    }
  }
`;

const ImgWrapper = styled.div`
  img {
    display: block;
    max-width: 100%;
  }
`;

const ProjectTile = ({ title, subtitle, image }) => (
  <ProjectTileContainer>
    <ImgWrapper>{image}</ImgWrapper>
    <Content>
      <ContentTitle>{title}</ContentTitle>
      <ContentSubtitle>{subtitle}</ContentSubtitle>
    </Content>
  </ProjectTileContainer>
);

ProjectTile.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.shape().isRequired,
};

export default ProjectTile;
