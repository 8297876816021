import React from 'react';
import styled from 'styled-components';

import {
  LaptopGncImg,
  LaptopCorralImg,
  LaptopImg,
} from '../img';
import ProjectTile from './ProjectTile';
import SectionTitle from './SectionTitle';
import { breakpoints } from '../../utils/constants';

const ProjectsContainer = styled.section`
  padding-top: 6.5rem;
  margin-top: -6.5rem;
`;

const ProjectsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${breakpoints.lg}) {
    justify-content: space-around;
  }
`;

const Projects = () => (
  <ProjectsContainer id="projects">
    <SectionTitle title="Recent Projects" subtitle="take a look" />
    <ProjectsList>
      <ProjectTile title="Landing Page" subtitle="GNC" image={<LaptopGncImg />} />
      <a
        href="https://www.corralbarandgrill.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <ProjectTile
          title="Website"
          subtitle="Corral Bar & Grill"
          image={<LaptopCorralImg />}
        />
      </a>
      <ProjectTile
        title="Inventory System"
        subtitle="Machine Shop"
        image={<LaptopImg />}
      />
    </ProjectsList>
  </ProjectsContainer>
);

export default Projects;
