import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Underline = styled.div`
  position: relative;
  z-index: 1;
  background: #ff4800;
  width: 55px;
  height: 3px;
  margin: 0.5rem 0 2rem;
  transition: 0.5s;

  &::before {
    right: 20px;
  }

  ::after {
    right: 10px;
  }

  &::before,
  ::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    top: 0;
    background: #ffffff;
    transition: 0.5s;
  }
`;

const SectionTitle = ({ title, subtitle }) => (
  <div>
    <span>{subtitle}</span>
    <h2 style={{ marginBottom: 0 }}>{title}</h2>
    <Underline />
  </div>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SectionTitle;
