import React from 'react';
import styled from 'styled-components';

import SectionTitle from './SectionTitle';
import Map from './Map';
import ContactForm from './ContactForm';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const SectionContainer = styled.section`
  padding-top: 6.5rem;
  margin-top: -6.5rem;
`;

const Contact = () => (
  <SectionContainer id="contact">
    <SectionTitle title="Contact Us" subtitle="get in touch" />
    <ContactContainer>
      <Map />
      <ContactForm />
    </ContactContainer>
  </SectionContainer>
);

export default Contact;
