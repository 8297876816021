import React from 'react';
import styled from 'styled-components';
import { CherrypalmLogoOnlyImg } from '../img';

import { colors, breakpoints } from '../../utils/constants';

const BannerContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5.5rem;
  margin-top: -5.5rem;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column-reverse;
  }
`;

const BannerTile = styled.div`
  width: 50%;

  @media (max-width: ${breakpoints.lg}) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

const BannerTileLogo = styled(props => <BannerTile {...props} />)`
  padding: 2rem;
  max-width: 500px;

  @media (max-width: ${breakpoints.lg}) {
    margin: 0 auto;
  }
`;

const Content = styled.div`
  padding: 5rem 0;

  @media (max-width: ${breakpoints.lg}) {
    padding: 0;
  }
`;

const SubTitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

const StartButton = styled.a`
  background: ${colors.cpOrange};
  border: 1px solid ${colors.cpOrange};
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-weight: 900;
  margin: 0 1rem 1rem 0;
  padding: 1rem 2rem;
  text-decoration: none;
`;

const CallNowButton = styled.a`
  border: 1px solid ${colors.cpOrange};
  border-radius: 2px;
  color: ${colors.cpOrange};
  display: inline-block;
  font-weight: 900;
  padding: 1rem 2rem;
  text-decoration: none;
`;

const Banner = () => (
  <BannerContainer id="home">
    <BannerTile>
      <Content>
        <h1>Quality Software in the Inland Empire</h1>
        <SubTitle>
          Cherrypalm is your local software solution for building your online e-commerce store,
          enhancing your web presence, and automating repetitive tasks through custom applications
        </SubTitle>
        <StartButton href="#contact">Get Started</StartButton>
        <CallNowButton href="tel:909-529-2772">Call 909-529-2772</CallNowButton>
      </Content>
    </BannerTile>
    <BannerTileLogo>
      <CherrypalmLogoOnlyImg />
    </BannerTileLogo>
  </BannerContainer>
);

export default Banner;
