import React from 'react';
import styled from 'styled-components';
import { FiPenTool } from 'react-icons/fi';
import { CherrypalmLogoImg } from '../img';

import { breakpoints, colors } from '../../utils/constants';
import SectionTitle from './SectionTitle';

const AboutContainer = styled.section`
  display: flex;
  flex-direction: row;
  padding-top: 5.5rem;
  margin-top: -5.5rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
  }
`;

const ContentContainer = styled.section`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  color: ${colors.cpOrange};
  font-size: 2em;
  padding: 0 1rem;
`;

const About = () => (
  <AboutContainer id="about">
    <ContentWrapper>
      <div>
        <SectionTitle title="About Us" subtitle="who we are" />
        <ContentContainer>
          <IconWrapper>
            <FiPenTool />
          </IconWrapper>
          <div>
            <h3>Skilled Software in the IE</h3>
            <p>
              Cherrypalm Software is a company based in the Inland Empire that is passionate about
              efficiency, beautiful design, and automating the mundane. We pride ourselves on
              helping other businesses increase revenue and cut costs with stunning websites and
              creative software solutions.
            </p>
          </div>
        </ContentContainer>
      </div>
    </ContentWrapper>
    <ContentWrapper>
      <CherrypalmLogoImg />
    </ContentWrapper>
  </AboutContainer>
);

export default About;
