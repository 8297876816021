import React from 'react';
import styled from 'styled-components';
import {
  FiFileText,
  FiBarChart,
  FiSettings,
  FiMail,
  FiMonitor,
  FiLink,
  FiDollarSign,
  FiTrello,
} from 'react-icons/fi';

import SectionTitle from './SectionTitle';
import ServiceTile from './ServiceTile';
import { breakpoints } from '../../utils/constants';

const ServiceContainer = styled.section`
  padding-top: 6.5rem;
  margin-top: -6.5rem;
`;

const ServiceList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const Services = () => (
  <ServiceContainer id="services">
    <SectionTitle title="Our Services" subtitle="what we do" />
    <ServiceList>
      <ServiceTile title="Website Development" icon={<FiTrello />} />
      <ServiceTile title="Landing Pages" icon={<FiFileText />} />
      <ServiceTile title="Metric Tracking" icon={<FiBarChart />} />
      <ServiceTile title="Process Automation" icon={<FiSettings />} />
      <ServiceTile title="Email Marketing" icon={<FiMail />} />
      <ServiceTile title="SEO" icon={<FiMonitor />} />
      <ServiceTile title="System Integrations" icon={<FiLink />} />
      <ServiceTile title="E-Commerce" icon={<FiDollarSign />} />
    </ServiceList>
  </ServiceContainer>
);

export default Services;
