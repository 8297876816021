import React from 'react';
import styled, { keyframes } from 'styled-components';

const scroll = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(75px);
  }
`;

const StyledDivider = styled.div`
  position: relative;
  overflow: hidden;
  height: 60px;
  width: 3px;
  background: #ff4800;
  right: 0;
  left: 0;
  margin: 3rem auto;
  z-index: 1;

  ::after {
    top: -15px;
  }

  &::before {
    top: -5px;
  }

  ::after,
  &::before {
    content: '';
    position: absolute;
    background: #ffffff;
    width: 100%;
    height: 3px;
    left: 0;
    z-index: -1;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: ${scroll};
  }
`;

export default () => <StyledDivider />;
