import React, { Component } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { breakpoints, colors } from '../../utils/constants';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const StyledInput = styled.input`
  height: 55px;
  background-color: #f4f4f4;
  border: none;
  padding-left: 15px;
  margin-right: 1rem;

  &::placeholder {
    color: #8a8a8a;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 1rem;
    margin-right: 0;
  }
`;

const StyledTextArea = styled.textarea`
  background-color: #f4f4f4;
  border: none;
  padding: 15px;
  margin-right: 1rem;
  width: 100%;

  &::placeholder {
    margin-top: 100px;
    color: #8a8a8a;
  }
`;

const StyledButton = styled.button`
  font-size: 15px;
  font-weight: 600;
  line-height: unset;
  text-transform: capitalize;
  padding: 13px 32px;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  background-color: ${colors.cpOrange};
  color: #ffffff;
  margin-top: 20px;
  border: 2px solid ${colors.cpOrange};

  &:hover {
    cursor: pointer;
  }
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  clearState() {
    const stateKeys = Object.keys(this.state);
    stateKeys.forEach(k => this.setState({ [k]: '' }));
  }

  async handleSubmit(e) {
    e.preventDefault();
    try {
      await Swal.fire({
        title: 'Success!',
        text: 'Your contact information has been received',
        type: 'success',
      });

      this.clearState();
    } catch (err) {
      await Swal.fire({
        title: 'Uh oh!',
        text: 'There seems to have been a problem. This error has been reported.',
        type: 'error',
      });
    }
  }

  render() {
    const {
      name, email, phone, subject, message,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit} name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
        <FormRow>
          <StyledInput
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={this.handleChange}
          />
          <StyledInput
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={this.handleChange}
          />
        </FormRow>
        <FormRow>
          <StyledInput
            id="phone"
            name="phone"
            type="text"
            placeholder="Phone"
            value={phone}
            onChange={this.handleChange}
          />
          <StyledInput
            id="subject"
            name="subject"
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={this.handleChange}
          />
        </FormRow>
        <FormRow>
          <StyledTextArea
            id="message"
            name="message"
            rows="4"
            placeholder="Message"
            value={message}
            onChange={this.handleChange}
          />
        </FormRow>
        <StyledButton type="submit">Submit Message</StyledButton>
        <input type="hidden" name="form-name" value="contact" />
      </form>
    );
  }
}

export default ContactForm;
