import React from 'react';

import Layout from '../components/home/Layout';
import SEO from '../components/shared/Seo';
import Banner from '../components/home/Banner';
import Features from '../components/home/Features';
import About from '../components/home/About';
import Services from '../components/home/Services';
import Projects from '../components/home/Projects';
import Contact from '../components/home/Contact';
import SectionDivider from '../components/home/SectionDivider';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Banner />
    <Features />
    <SectionDivider />
    <About />
    <SectionDivider />
    <Services />
    <SectionDivider />
    <Projects />
    <SectionDivider />
    <Contact />
  </Layout>
);

export default IndexPage;
