import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../utils/constants';

const StyledHeader = styled.h3`
  font-size: 1rem;
  transition: 500ms;
  margin: 1rem 0;
`;

const Item = styled.div`
  background: #f9f9f9;
  color: #000;
  position: relative;
  z-index: 1;
  padding: 20px 55px 20px 108px;
  transition: 0.5s;
  border-radius: 65px 0 0 65px;
  margin: 0 1rem 1rem;
  flex: 1 0 30%;
  min-width: 250px;

  &:hover {
    background: ${colors.cpOrange};
    color: #fff;
  }

  &:hover ${StyledHeader} {
    color: #fff;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  z-index: -1;
  width: 75px;
  height: 75px;
  background: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: ${colors.cpOrange};
  line-height: 90px;
  margin-top: -38px;
  transition: 500ms;

  svg {
    height: 35px;
    width: 35px;
  }
`;

const ServiceTile = ({ title, icon }) => (
  <Item>
    <IconWrapper>{icon}</IconWrapper>
    <StyledHeader>{title}</StyledHeader>
  </Item>
);

ServiceTile.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape().isRequired,
};

export default ServiceTile;
