import React from 'react';
import styled, { keyframes } from 'styled-components';

import { MapDarkImg } from '../img';
import { colors, breakpoints } from '../../utils/constants';

const MapContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const Ripple = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
`;

const Location = styled.div`
  position: absolute;
  left: 13%;
  top: 27%;
  width: auto;
  height: auto;
  z-index: 1;
  background: ${colors.cpOrange};

  span {
    width: 10px;
    height: 10px;
    background: ${colors.cpOrange};
    border-radius: 50%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -4px;
      bottom: -40px;
      border: 4px solid ${colors.cpOrange};
      width: 18px;
      height: 18px;
      border-radius: 50%;
      animation-name: ${Ripple};
      animation-duration: 2s;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    }
  }
`;

const LocationInfo = styled.div`
  position: absolute;
  left: 3%;
  top: 0;
  transition: 500ms;
  width: 160px;
  background: #fff;
  text-align: center;
  padding: 20px 15px;
  border-radius: 3px;
  z-index: 1;
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);

  &::before {
    content: '';
    position: absolute;
    left: 50px;
    bottom: -10px;
    width: 25px;
    height: 25px;
    background: #fff;
    z-index: -1;
    transform: rotate(45deg);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    color: #616161;
  }

  span {
    display: block;
    font-size: 14px;
    color: #616161;
    margin-top: 6px;
  }
`;

const Map = () => (
  <MapContainer>
    <Location>
      <span />
    </Location>
    <LocationInfo>
      <h5>Inland Empire</h5>
      <span>Chino, CA</span>
    </LocationInfo>
    <div style={{ width: '575px' }}>
      <MapDarkImg />
    </div>
  </MapContainer>
);

export default Map;
